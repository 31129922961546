@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@600;700&display=swap');

$primary: white;
$font-family-base: "Barlow Semi Condensed", "sans-serif";
$link-decoration: none;

$btn-font-size: .9rem;
$btn-line-height: 1.5;
$btn-font-weight: bold;
$btn-padding-x: 2rem;
$btn-padding-y: .5rem;
$headings-color: white;
//$input-btn-font-size: 22px;

$modal-content-bg: "#181D2B";
$modal-content-border-radius: "15px";

@import "../../node_modules/bootstrap/scss/bootstrap";
