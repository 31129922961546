@import "custom";

$background-primary: #181D2B;

.bgImage {
  background: url('../../public/images/bg.jpg') no-repeat;
  background-size: cover;
  background-position: center center;
}

.startContainer {
  width: 100vw;
  height: 100vh;
}

.header {
  height: 150px
}

.logo {
}

.cart-icon {
  display: block;
  background: none;
  border-radius: 10px;
  border: 3px solid $white;
  padding: .8rem;
  padding-left: .6rem;
  width: 70px;
  height: 70px;
  text-align: center;

  img {
    width: 100%;
  }
}

.sidebar {
  background: $background-primary;
  min-height: 100vh;
  padding-top: 2rem;

  .vstack {
    height: 95vh;
  }

  a.logo {
    margin-bottom: 2rem;
  }

  a:not(.logo) {
    display: block;
    background: none;
    border-radius: 10px;
    border: 3px solid $white;
    padding: .8rem;

    svg {
      fill: $white;
    }
  }

  a.active {
    background: $white;

    svg {
      fill: $black;
    }
  }
}

.main {
  height: 100vh;
}

.body-catalog {
  height: calc(100vh - 150px);
  background: #0F1019;
  flex: 1;
  padding: 1rem 2rem;

  .product-card {
    display: block;
    flex: 0 1 200px;
    position: relative;
    background: $background-primary;
    margin: 50px 10px 20px;
    padding: 200px 20px 10px;
    border-radius: 10px;

    .product-image {
      position: absolute;
      width: 80%;
      aspect-ratio: .7;
      left: 20px;
      top: -40px;
      border-radius: 10px;
      background-color: $white;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

.product-detail {

  img {
    border-radius: 10px;
  }

  .name, .price {
    color: $white;
    text-align: center;
    text-transform: uppercase;
  }

  .name {
    font-size: 1.5rem;
  }

  .price {
    font-size: 2.5rem;
  }

  .product-content {
    height: calc(87vh - 150px);
    overflow: auto;
  }
}

.cart, .cart-summary {
  background: $background-primary;
  padding: 40px 20px 10px;

  hr {
    color: #465377;
  }

  .total, .total-payment {
    color: #465377;
    height: 80px;
    text-transform: uppercase;
    font-size: 1.1rem;
  }

  .total-payment {
    height: auto;
    margin-bottom: .5rem;
  }
}

.pin-pad {
  .number {
    margin-bottom: 1rem;
    text-align: center;

    button {
      font-size: 1.7rem;
      height: 70px;
      border-radius: 50px;
    }
  }
}

.history {
  .balance {
    margin: -1rem -2.7rem 0rem;
    background: #465377;
    padding: .7rem 0;

    h5 {
      text-transform: uppercase;
      margin-bottom: 0;
      padding-left: .5rem;
    }
  }

  div:nth-child(2) {
    border-top: 0;
  }

  .history-unit {
    padding-top: 2rem;
    margin-bottom: 4rem;
    border-top: 1px dotted $white;

    .unit-header {
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      hr {
        border-bottom: 1px solid #465377;
      }
    }

    .unit-line {
      margin-bottom: 1rem;
    }
  }
}

.loading {
  width: 100%;
  height: 500px;
  line-height: 500px;
  color: $primary;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
}
